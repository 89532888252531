/**
 * @typedef {'learn' | 'meditate' | 'sleep'} Purpose
 */

/**
 * Enum for course purpsoe values.
 * @readonly
 * @enum {Purpose}
 */
const PURPOSE = Object.freeze({
  LEARN: 'learn',
  MEDITATE: 'meditate',
  SLEEP: 'sleep',
});

export default PURPOSE;
