<script>
  import { mapActions, mapGetters, mapMutations } from 'vuex';
  import { PURPOSE, TYPE } from '@/enums';
  import CurvedHeader from '@/components/general/curved-header/CurvedHeader.vue';
  import CourseGridCard from '@/components/courses/course-grid-card/CourseGridCard.vue';
  import CourseFiltersDialog from '@/components/courses/course-filters-dialog/course-filters-dialog/CourseFiltersDialog.vue';
  import courseFiltersMixin from '@/mixins/courseFiltersMixin.js';

  /**
   * @typedef {import('@/enums/type.js').Type} Type
   */

  export default {
    mixins: [courseFiltersMixin],
    name: 'courses-by-purpose',
    data() {
      return {
        isFetchingCourses: false,
        purposeCourses: [],
        types: ['All', 'Exercises', 'Education'],
        tab: 0,
        /**
         * @type {Type}
         */
        type: 'all',
        exerciseCourses: [],
        educationCourses: [],
      };
    },
    components: {
      CurvedHeader,
      CourseGridCard,
      CourseFiltersDialog,
    },
    watch: {
      async courses() {
        await this.fetchCourses(false);
        this.setPurposeCourses();
        this.setTabsCourses();
        this.filterTabsCourses(false);
      },
      tab() {
        this.mapTabToType();
      },
      type() {
        this.$nextTick(() => this.filterTabsCourses(false));
      },
    },
    computed: {
      ...mapGetters('CoursesModule', ['courses', 'learnCourses', 'meditateCourses', 'sleepCourses']),
      purpose() {
        return this.$route.params.purpose;
      },
    },
    async created() {
      await this.fetchCourses(false);
      this.setPurposeCourses();
      this.setTabsCourses();
      this.filterTabsCourses(false);
    },
    methods: {
      ...mapMutations(['setErrorSnackbarMessage', 'setSuccessSnackbarMessage']),
      ...mapActions('CoursesModule', ['fetchAll']),
      async fetchCourses() {
        this.isFetchingCourses = true;

        try {
          await this.fetchAll();
        } catch (error) {
          this.setErrorSnackbarMessage(error);
        } finally {
          this.isFetchingCourses = false;
        }
      },
      setPurposeCourses() {
        switch (this.purpose) {
          case PURPOSE.LEARN:
            this.purposeCourses = this.learnCourses;
            break;
          case PURPOSE.MEDITATE:
            this.purposeCourses = this.meditateCourses;
            break;
          case PURPOSE.SLEEP:
            this.purposeCourses = this.sleepCourses;
            break;
        }
      },
      setTabsCourses() {
        this.emptyTabs();
        for (const id in this.purposeCourses) {
          if (Object.hasOwnProperty.call(this.purposeCourses, id)) {
            const course = this.purposeCourses[id];
            // If course type is `exercise`, we put it in the Exercises tab
            if (course.type === TYPE.EXERCISE) this.exerciseCourses.push(course);
            // If course type is `education`, we put it in the Education tab
            if (course.type === TYPE.EDUCATION) this.educationCourses.push(course);
          }
        }
      },
      filterTabsCourses(showSnackbar = true) {
        switch (this.type) {
          case TYPE.EXERCISE:
            this.filterCourses(this.exerciseCourses, showSnackbar);
            break;
          case TYPE.EDUCATION:
            this.filterCourses(this.educationCourses, showSnackbar);
            break;
          case TYPE.ALL:
            this.filterCourses(this.purposeCourses, showSnackbar);
            break;
        }
      },
      resetTabsCourses() {
        switch (this.type) {
          case TYPE.EXERCISE:
            this.resetCourses(this.exerciseCourses);
            break;
          case TYPE.EDUCATION:
            this.resetCourses(this.educationCourses);
            break;
          case TYPE.ALL:
            this.resetCourses(this.purposeCourses);
            break;
        }
      },
      /**
       * Empty the tabs to avoid adding duplicates
       */
      emptyTabs() {
        this.exerciseCourses = [];
        this.educationCourses = [];
      },
      /**
       * Since vuetify uses a number to work with tabs, I created this helper function to map
       * types to each tab.
       */
      mapTabToType() {
        switch (this.tab) {
          case 0:
            this.type = TYPE.ALL;
            break;
          case 1:
            this.type = TYPE.EXERCISE;
            break;
          case 2:
            this.type = TYPE.EDUCATION;
            break;
        }
      },
    },
  };
</script>
